export default function ServiceDetailsSecurity() {

    const banner = {
        background: 'url(/images/tap.jpg)',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center'
    }

    return (
        <>
            <section className="w3l-inner-banner" style={banner}>
                <div className="wrapper">
                    {/* Content for the inner banner can go here */}
                </div>
            </section>

            <section className="w3l-content-8">
                <section className="content-main">
                    <h5 className="heading">Service details</h5>
                    <h3 className="heading">Security</h3>
                    <div className="wrapper">
                        <div className="grid">
                            <div className="left-align">
                                <h3>Our Guards make sure they keep you safe and manage situations</h3>
                                <p className="main-pre">
                                    We employ hundreds of security guards, serving our esteemed clients. We train our security guards and prepare
                                    them for all kinds of challenges. We offer excellent and efficient security services in Bangalore, unmatched in the
                                    industry to our valued clients. We understand the needs of our clients completely and provide customized security
                                    services.
                                </p>
                            </div>
                            <div className="right-align">
                                <p className="main-pre">
                                    We deploy fully trained security guards who can respond aptly to all kinds of situations and protect our
                                    clients and their assets. Our security guards are trained to work with all types of commercial & residential
                                    establishments. We verify the credentials of our security guards fully before deploying them at our clients’ place.
                                </p>
                                <div className="iner-align">
                                    <div className="iner-left">
                                        <h4>What we do</h4>
                                        <a href="#">Train Smart Guards</a>
                                        <a href="#">Making them work smart</a>
                                        <a href="#">Handle Ledgers</a>
                                        <a href="#">Help in managing situations</a>
                                    </div>
                                    <div className="iner-right">
                                        <h4>How we work</h4>
                                        <a href="#">230+ professional experts</a>
                                        <a href="#">High quality service</a>
                                        <a href="#">Project on time service</a>
                                    </div>
                                </div>
                            </div>
                            <div className="img">
                                {/* Replace the src below with a valid image URL */}
                                <img src="/images/security-guard.jpg" alt="Security Services" />
                            </div>
                        </div>
                    </div>
                </section>
            </section>
        </>
    );
}

import { useEffect, useState } from "react";
import { endPointURL, get_data } from "../../api";

export default function HeroSection() {

    const [banners, setBanners] = useState([]);

    const getAllBanners = async () => {
        try {
            const response = await get_data(`banners/get-all-banners`);
            if (response.status) {
                setBanners(response?.data?.sort((a, b) => a?.order - b?.order))
            }
        } catch (error) {
            console.error("Error fetching banners:", error);
        }
    };

    useEffect(() => {
        getAllBanners();
    }, []);


    const allBanners = [
        {
            image: banners?.[0]?.banner,
            title: 'Home Maintenance and Services for you',
            description: "We aim to serve you with the best and quality service available in the industry. We make sure all your home shines with our work."
        },
        {
            image: banners?.[1]?.banner,
            title: 'We are Professional and provide repair Services',
            description: "We provide any kind of repairs that are needed for your house. We have experienced and trained workers who can deliver quality results."
        },
        {
            image: banners?.[2]?.banner,
            title: 'The Best company to provide home Services',
            description: "We are the best company and we also believe in working hard to make sure we remain the best by delivering quality services to clients like you."
        },
        {
            image: banners?.[3]?.banner,
            title: 'The Most targetted cleaning services',
            description: "Our Cleaning services are one of the best in the industry. We know exactly where the dust is hidden and know exactly how to treat it."
        },
    ]


    return (
        <section className="w3l-covers-9-main">
            <div className="covers-9">
                <div className="csslider infinity" id="slider1">
                    {allBanners?.length > 0 && allBanners?.map((banner, index) => (
                        <input
                            key={index}
                            type="radio"
                            name="slides"
                            id={`slides_${index + 1}`}
                            defaultChecked={index === 0}
                        />
                    ))}
                    <ul className="banner_slide_bg" style={{ height: '95vh' }}>
                        {allBanners?.map((banner, index) => {
                            return (
                                <li key={index} style={{ background: `url(${endPointURL}/uploads/banners-picture/${banner?.image})` }} className="bannerSlides">
                                    <div className="wrapper">
                                        <div className="cover-top-center-9">
                                            <div className="w3ls_cover_txt-9">
                                                <h6 className="tag-cover-9">We provide quality service</h6>
                                                <h3 className="title-cover-9">{banner.title}</h3>
                                                <p className="para-cover-9">{banner.description}</p>
                                                <a href="/about" className="actionbg button-cover-9">Read More</a>
                                                <a href="/contact" className="actionbg-border button-cover-9">Get in touch</a>
                                            </div>
                                        </div>
                                    </div>
                                </li>
                            )
                        })}
                    </ul>
                    <div className="arrows">
                        {allBanners?.map((_, index) => (
                            <label key={index} htmlFor={`slides_${index + 1}`}></label>
                        ))}
                    </div>
                    <div className="navigation">
                        <div>
                            {allBanners?.map((_, index) => (
                                <label key={index} htmlFor={`slides_${index + 1}`}></label>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

import { useEffect, useState } from "react";
import { endPointURL, get_data } from "../../api";
import Headers from "../components/Headers"
import Footer from "../components/Footer"
import CTA from "../components/CTA"
import { useParams } from "react-router-dom";
import parse from 'html-react-parser';
import ScreenLoading from "../components/ScreenLoading";
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';
import { useTheme } from '@mui/material/styles';
import { useMediaQuery } from "@mui/material";

export default function SinglePost() {

    const [loader, setLoader] = useState(true)
    const [data, setData] = useState({})
    const theme = useTheme();
    const matches_md = useMediaQuery(theme.breakpoints.down('md'))

    const { slug } = useParams();

    const getPostDetails = async () => {
        try {
            const data = await get_data(`post/get-post-by-slug/${slug}`);
            if (data.status) {
                setData(data?.data);
            }
        } catch (error) {
            setLoader(false);
        }
    }
    useEffect(() => {
        getPostDetails()
    }, [])


    useEffect(() => {
        setTimeout(() => {
            setLoader(false);
        }, 2000);
    }, [])

    const styles = {
        container: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',
            padding: matches_md ? '20% 4% 3%' : '3% 10%',
        },
        title: {
            fontWeight: 600,
            fontSize: matches_md ? 35 : 45,
            marginTop: '3%',
            textAlign: 'center'
        },
        content: {
            padding: matches_md ? '3% 0' : '3% 5%',
            fontSize: 18,
            width: '100%',
            textAlign: 'left'
        },
        categoryContainer: {
            marginTop: '1%',
            marginBottom: '2%',
        }
    }

    return (
        <>
            {
                loader ?
                    <>
                        <ScreenLoading />
                    </>
                    :
                    <>
                        <Headers />
                        <div style={styles.container}>
                            <img
                                style={{
                                    width: '100%',
                                    marginTop: '6%',
                                    height: matches_md ? '100%' : 600,
                                    borderRadius: 10
                                }}
                                src={`${endPointURL}/uploads/post-pictures/${data?.featured_image}`}
                            />
                            <h2 style={styles.title}>{data?.title}</h2>
                            <Stack direction="row" spacing={1} style={styles.categoryContainer}>
                                {data?.category?.map((category, index) => (
                                    <Chip key={index} label={category?.category_name} />
                                ))}
                            </Stack>
                            <div className="content" style={styles?.content}>{data?.content ? parse(data.content) : "Loading..."}</div>
                        </div>
                        <CTA />
                        <Footer />
                    </>
            }
        </>
    )
}

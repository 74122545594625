import React from 'react';

export default function WhatWeOffer() {
    return (
        <>
            <section className="w3l-text-25-main">
                <div className="text-25">
                    <div className="wrapper">
                        <h5 className="heading">What we offer</h5>
                        <h3 className="heading">Few more services</h3>
                        <div className="text-25-content">
                            <div className="d-grid grid-columns">
                                <div className="d-grid inner-grid-columns">
                                    <div className="column1">
                                    </div>
                                    <div className="column-two">
                                        <div className="box13">
                                            <h3><a href="#">Plumbing</a></h3>
                                            <p>Cut, assemble and install pipes and tubes with attention to existing infrastructure (e.g. electrical wiring) Install and maintain water supply systems. Locate and repair issues with water supply lines (e.g. leaks) Repair or replace broken drainage lines, clogged drains, faucets etc.</p>
                                            <a href="#" className="action btn">Get a Quote</a>
                                        </div>
                                    </div>
                                </div>
                                <div className="d-grid inner-grid-columns">
                                    <div className="column2">
                                    </div>
                                    <div className="column-two">
                                        <div className="box13">
                                            <h3><a href="#">Electrical Wiring</a></h3>
                                            <p>Executing plans of electrical wiring for well functioning lighting, intercom and other electrical systems. Installing electrical apparatus, fixtures and equipment for alarm and other systems. Installing safety and distribution components.</p>
                                            <a href="#" className="action btn">Get a Quote</a>
                                        </div>
                                    </div>
                                </div>
                                <div className="d-grid inner-grid-columns">
                                    <div className="column3">
                                    </div>
                                    <div className="column-two">
                                        <div className="box13">
                                            <h3><a href="#">Carpentry</a></h3>
                                            <p>Construct, install, and repair a variety of residential, commercial, and industrial structures and fixtures. In general, carpenters work with wood, steel, and concrete. Carpenters are also often involved with demolition and maintenance of these structures and fixtures.</p>
                                            <a href="#" className="action btn">Get a Quote</a>
                                        </div>
                                    </div>
                                </div>
                                <div className="d-grid inner-grid-columns">
                                    <div className="column4">
                                    </div>
                                    <div className="column-two">
                                        <div className="box13">
                                            <h3><a href="#">House Cleaning</a></h3>
                                            <p>The residential cleaner will be responsible for cleaning and maintaining the household and its surrounds. Your responsibilities will include sweeping, washing, dusting, mopping, vacuuming, scrubbing, and sanitizing all areas and surfaces. You should also be proficient in making beds, and ironing and folding laundry.</p>
                                            <a href="#" className="action btn">Get a Quote</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

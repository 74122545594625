import Footer from "../components/Footer";
import Header from "../components/Headers";
import ServiceDetails from "../components/ServiceDetails";

export default function HouseKeeping() {
    return (<>
        <Header />
        <ServiceDetails />
        <Footer />
    </>)
}
import React from 'react';
import { Card, CardContent, Typography, CardMedia, Box, Grid, IconButton, Checkbox } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { endPointURL, serverURL } from "../../api"
import { useNavigate } from 'react-router-dom';
import moment from 'moment'

const PostCard = ({ setScreenLoading, item }) => {

    const theme = useTheme();
    const matches_md = useMediaQuery(theme.breakpoints.down('md'))
    const navigate = useNavigate()

    const handleClick = () => {
        navigate(`/post/${item?.slug}`, { state: { post: item } })
    }

    const getDays = (createdDate) => {
        const createdAt = moment(createdDate).startOf('day'); // Given date, ignoring time
        const currentDate = moment().startOf('day'); // Current date, ignoring time

        // Calculate the difference in days
        const diffInDays = currentDate.diff(createdAt, 'days');

        // Check if the difference is less than 1 day
        if (diffInDays < 1) {
            return "Today"
        }
        else if (diffInDays === 1) {
            return diffInDays + ' Day'
        } else {
            return diffInDays + ' Days'
        }
    }


    return (
        <div onClick={handleClick}>
            <Card sx={{ maxWidth: matches_md ? '100%' : 345, borderRadius: 2, cursor: 'pointer', padding: 0, boxShadow: '4px 4px 44px rgba(0,5,46,.08)' }} >
                <Box>
                    <img
                        style={{
                            width: '100%',
                            height: 200,
                        }}
                        src={`${endPointURL}/uploads/post-pictures/${item?.featured_image}`}
                    />
                </Box>
                <CardContent sx={{ padding: 2 }}>

                    <Typography variant="body1" component="div" sx={{ fontWeight: 550, margin: '1% 0', fontSize: matches_md ? 22 : 17, height: 80 }}>
                        {item?.title}
                    </Typography>

                    <Grid container spacing={1} sx={{ marginTop: '7%', paddingTop: '2%', borderTop: '1px solid #F0EFFB' }}>
                        <Grid item xs={9} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'left' }}>
                            <Typography variant="caption" sx={{ marginLeft: 0.5, textAlign: 'center' }}>
                                {item?.category?.map((item) => item.category_name).join(",")}
                            </Typography>
                        </Grid>

                        <Grid item xs={3} sx={{ display: 'flex', alignItems: 'center', flexDirection: matches_md ? 'column' : '' }}>
                            <Typography variant="caption" sx={{ marginLeft: 0.5 }}>
                                {getDays(item?.created_at)}
                            </Typography>
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>
        </div>
    );
};

export default PostCard;

import emailjs from 'emailjs-com';
import React, { useState } from 'react';
import { post_data } from '../../api';

export default function Brochure() {

    const downloadPDF = async () => {
        const response = await fetch('/images/brochure.pdf');
        const blob = await response.blob();
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = 'brochure.pdf';
        document.body.appendChild(a);
        a.click();
        a.remove();
    };

    return (
        <>
            <section className="w3l-form-16" style={{ background: '#F8F9FA', padding: '1% 5%' }}>
                <div className="form-16-mian">
                    <div className="wrapper">
                        <div className="forms-16-top">
                            <div className="forms-16-info">
                                <h5>Brochure</h5>
                                <h3>Downlod our Brochure</h3>
                                <p>
                                    Having 16 years of operational experience, The top management reachable presence in south India excellent manpower
                                    resources are 100% statutory compliance. Download our Brochure to know about us and our services in more detail.
                                </p>
                                <a style={{ cursor: 'pointer' }} onClick={downloadPDF} className="read1">Download</a>
                            </div>
                            <div className="form-right-inf" style={{ padding: 0 }}>
                                <center>
                                    <img
                                        style={{ height: 350, border: '20px solid #333333' }}
                                        src='/images/cover.png'
                                    />
                                </center>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

import AllServicesComponent from "../components/AllServicesComponent";
import Footer from "../components/Footer";
import Header from "../components/Headers";
import WhatWeOffer from "../components/WhatWeOffer";

export default function AllServices() {

    const banner = {
        background: 'url(/images/tap.jpg)',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center'
    }

    return (<>
        <Header />
        <section className="w3l-inner-banner" style={banner}>
            <div className="wrapper"></div>
        </section>
        <AllServicesComponent />
        <WhatWeOffer />
        <Footer />
    </>)
}
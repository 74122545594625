import { Grid, Pagination, Typography, useMediaQuery } from "@mui/material";
import PostCard from "./PostCard";
import { get_data } from "../../api";
import { useEffect, useState } from "react";
import CircularProgress from '@mui/material/CircularProgress';
import { useTheme } from '@mui/material/styles';

const AllPosts = () => {

    const theme = useTheme();
    const matches_md = useMediaQuery(theme.breakpoints.down('md'))

    const styles = {
        container: {
            display: 'flex',
            flexDirection: 'column',
            padding: matches_md ? '20% 6% 6%' : '6%',
        },
        card: {
            flex: '1 1 calc(33.33% - 20px)',
            boxSizing: 'border-box',
        }
    };

    const [loader, setLoader] = useState(true);
    const [postData, setPostData] = useState([]);

    const [totalPages, setTotalPages] = useState(null);
    const [totalPost, setTotalPost] = useState(null);
    const [currentPage, setCurrentPage] = useState(1);


    const getAllPosts = async () => {
        try {
            const data = await get_data(`post/get-all-posts?pageNumber=${currentPage}`);
            if (data.status) {
                setLoader(false);
                setPostData(data?.data?.posts);
                setTotalPages(data?.data?.totalPages);
                setTotalPost(data?.data?.totalPost);
            }
        } catch (error) {
            setLoader(false);
        }
    }
    useEffect(() => {
        getAllPosts()
    }, [currentPage])

    const itemsPerPage = 15;
    const count = postData?.length;


    const startEntry = (currentPage - 1) * itemsPerPage + 1;
    const endEntry = Math.min(currentPage * itemsPerPage, totalPost);

    const handlePageChange = (event, value) => {
        setCurrentPage(value);
        setLoader(true)
    };

    return (
        <>
            {
                loader ?
                    <>
                        <div style={{ display: 'flex', justifyContent: 'center', padding: '10% 0' }}>
                            <CircularProgress />
                        </div>
                    </>
                    :
                    <>
                        <div style={styles.container} className="blog-page">
                            <Grid container spacing={4}>
                                {
                                    postData?.map((item, i) => {
                                        return (
                                            <Grid item md={3} key={i} style={{ width: matches_md ? '100%' : '' }}>
                                                <PostCard item={item} />
                                            </Grid>
                                        )
                                    })
                                }
                            </Grid>

                            {postData?.length > 0 && (
                                <div
                                    style={{
                                        borderTop: "1px dashed gainsboro",
                                        paddingTop: "2%",
                                        display: "flex",
                                        marginTop: '5%',
                                        justifyContent: "space-between",
                                    }}
                                >
                                    <Typography
                                        style={{
                                            fontWeight: 500,
                                            fontSize: 15,
                                            color: "black",
                                        }}
                                    >{`Showing ${startEntry} to ${endEntry} of ${totalPost} Records`}</Typography>
                                    <Pagination
                                        count={totalPages}
                                        page={currentPage}
                                        onChange={handlePageChange}
                                        color="primary"
                                    />
                                </div>
                            )}
                        </div>

                    </>
            }
        </>
    );
}

export default AllPosts;

export default function PartnerForm() {

    const banner = {
        background: 'url(/images/tap.jpg)',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center'
    }

    return (
        <>
            <section className="w3l-inner-banner" style={banner}>
                <div className="wrapper"></div>
            </section>

            <section class="w3l-contacts-12">
                <div class="wrapper">
                    <h5 class="heading">Expect Quality</h5>
                    <h3 class="heading">Nivishka Partner Programme</h3>
                    <div class="contacts12-main">
                        <form action="https://sendmail.w3layouts.com/submitForm" method="post">
                            <div class="main-input">
                                <input type="text" name="w3lName" placeholder="Your full name" class="contact-input1" required />
                                <input type="email" name="w3lSender" placeholder="Your email address" class="contact-input1" required />
                                <input type="number" name="w3lPhone" placeholder="Your phone number" class="contact-input1" required />
                            </div>
                            <textarea class="contact-textarea" name="w3lMessage" placeholder="Your message here" required></textarea>
                            <button class="actionbg btn">Submit</button>
                        </form>
                    </div>
                </div>
            </section>
        </>
    );
}

import React, { useState } from 'react';
import emailjs from 'emailjs-com';
import { post_data } from '../../api';
export default function GetInTouch() {
    // State to manage form submission status
    const [status, setStatus] = useState('');
    const [formValues, setFormValues] = useState({});
    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormValues({ ...formValues, [name]: value });
    };

    // Function to handle form submission
    // const handleSubmit = (e) => {
    //     e.preventDefault(); // Prevent default form submission
    //     setStatus('loading'); // Set status to loading when form is submitted

    //     emailjs.send(
    //         'service_9k8r7wk',
    //         'template_2a6gb19',
    //         formValues,
    //         'fJrp5_Z6y8nXUV6Ze'
    //     ).then((result) => {
    //         alert('Email sent');
    //         setStatus('success');
    //     }, (error) => {
    //         setStatus('');
    //         console.log("error.text", error);
    //     });
    // };


    const handleSubmit = async (event) => {
        event.preventDefault();
        try {
            const data = await post_data(`forms/send-contact-message`, formValues)
            if (data.status) {
                setStatus('success');
            }
            else {
            }
        } catch (error) {
        }
    }

    const banner = {
        background: 'url(/images/tap.jpg)',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center'
    };

    return (
        <>
            <section className="w3l-inner-banner" style={banner}>
                <div className="wrapper"></div>
            </section>

            <section className="w3l-contacts-12">
                <div className="wrapper">
                    <h5 className="heading">Write to us</h5>
                    <h3 className="heading">Get in touch</h3>
                    <div className="contacts12-main">
                        <form id="contact-form" onSubmit={handleSubmit}>
                            <div className="main-input">
                                <input
                                    type="text"
                                    name="name"
                                    onChange={handleChange}
                                    placeholder="Your full name"
                                    className="contact-input1"
                                    required
                                />
                                <input
                                    type="email"
                                    name="email"
                                    onChange={handleChange}
                                    placeholder="Your email address"
                                    className="contact-input1"
                                    required
                                />
                                <input
                                    type="number"
                                    name="phone"
                                    onChange={handleChange}
                                    placeholder="Your phone number"
                                    className="contact-input1"
                                    required
                                />
                                <input
                                    type="text"
                                    name="subject"
                                    onChange={handleChange}
                                    placeholder="Your subject"
                                    className="contact-input1"
                                    required
                                />
                            </div>
                            <textarea
                                className="contact-textarea"
                                name="message"
                                onChange={handleChange}
                                placeholder="Your message here"
                                required
                            ></textarea>
                            <button
                                className="actionbg btn"
                                type="submit"
                                id="submit-btn"
                            >
                                Submit
                            </button>
                        </form>
                        {/* Display appropriate message based on the status */}
                        {status === 'loading' && (
                            <p
                                style={{
                                    color: 'blue',
                                    fontSize: '20px',
                                    marginTop: '20px',
                                }}
                                id="loading"
                            >
                                Sending Message...
                            </p>
                        )}
                        {status === 'success' && (
                            <p
                                style={{
                                    color: 'green',
                                    fontSize: '16px',
                                }}
                                id="success-msg"
                            >
                                We received your message successfully
                            </p>
                        )}
                        {status === 'error' && (
                            <p
                                style={{
                                    color: 'red',
                                    fontSize: '16px',
                                }}
                                id="error-msg"
                            >
                                Something went wrong. Please try again.
                            </p>
                        )}
                    </div>
                </div>
            </section>
        </>
    );
}

export default function ServiceDetailsDemolition() {

    const banner = {
        background: 'url(/images/tap.jpg)',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center'
    }

    return (
        <>
            <section className="w3l-inner-banner" style={banner}>
                <div className="wrapper"></div>
            </section>

            <section className="w3l-content-8">
                <section className="content-main">
                    <h5 className="heading">Service details</h5>
                    <h3 className="heading">Demolition</h3>
                    <div className="wrapper">
                        <div className="grid">
                            <div className="left-align">
                                <h3>Demolishing structures that is no more relevant</h3>
                                <p className="main-pre">
                                    The demolition of a building is the act of deliberately destroying it,
                                    often in order to build something else in its place.
                                    The project required the total demolition of the old bridge.
                                    The demolition of a team or opponent is their defeat by a great amount.
                                    Before any demolition activities can take place, there are many steps
                                    that must be carried out beforehand, including performing asbestos abatement,
                                    removing hazardous or regulated materials, obtaining necessary permits,
                                    submitting necessary notifications, disconnecting utilities, rodent baiting
                                    and the development of site-specific safety and work plans.
                                </p>
                            </div>
                            <div className="right-align">
                                <p className="main-pre">
                                    Hydraulic hammers are often used for concrete structures and concrete
                                    processing attachments are used to crush concrete to a manageable size,
                                    and to remove reinforcing steel. For tall concrete buildings, where neither
                                    explosive nor high reach demolition with an excavator is safe or practical,
                                    the "inside-out" method is used, whereby remotely operated mini-excavators
                                    demolish the building from the inside, whilst maintaining the outer walls
                                    of the building as a scaffolding, as each floor is demolished.
                                </p>
                                <div className="iner-align">
                                    <div className="iner-left">
                                        <h4>What we do</h4>
                                        <a href="#">Safe Demolition</a>
                                        <a href="#">Legal Works</a>
                                        <a href="#">Make sure nothing remains</a>
                                        <a href="#">Waste Removal</a>
                                    </div>
                                    <div className="iner-right">
                                        <h4>How we work</h4>
                                        <a href="#">230+ professional experts</a>
                                        <a href="#">High quality materials</a>
                                        <a href="#">Project on time service</a>
                                    </div>
                                </div>
                            </div>
                            <div className="img">
                                <img
                                    height="300" width="450"
                                    src="/images/demolition-1.jpeg"
                                />
                            </div>
                            <div class="img">
                                <img
                                    height="300" width="450"
                                    src="/images/demolition-2.jpeg"
                                />
                            </div>
                        </div>
                    </div>
                </section>
            </section>
        </>
    );
}

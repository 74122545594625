import { useEffect, useState } from "react";
import { endPointURL, get_data } from "../../api";
import { CircularProgress } from "@mui/material";

export default function Clients() {

    const [loader, setLoader] = useState(true)
    const [data, setData] = useState([])

    const getAllClients = async () => {
        try {
            const data = await get_data("clients/get-all-clients");
            if (data.status) {
                setLoader(false);
                setData(data?.data);
            }
        } catch (error) {
            setLoader(false);
        }
    }

    useEffect(() => {
        getAllClients();
    }, [])

    return (
        <section className="w3l-customers-8">
            <div className="customers-main">
                <div className="wrapper">
                    <h5 className="heading">Clients</h5>
                    <h3 className="heading">Who are we serving currently?</h3>
                    <div className="customer">

                        {
                            loader ?
                                <></>
                                :
                                <>
                                    {
                                        data?.map((item) => {
                                            return (
                                                <>
                                                    <div className="card" style={{ marginTop: '5%' }}>
                                                        <img className="card-img-top img-responsive"
                                                            src={`${endPointURL}/uploads/clients-pictures/${item?.logo}`}
                                                            alt="Aastrika"
                                                        />
                                                        <div className="card-body">
                                                            <a href="#link" className="customer-link">
                                                                <h3 className="card-title" style={{ marginTop: '10%' }}>{item?.name}</h3>
                                                            </a>
                                                        </div>
                                                    </div>
                                                </>
                                            )
                                        })
                                    }
                                </>
                        }

                    </div>
                </div>
            </div>
        </section>
    );
}

import { useEffect, useState } from 'react';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Header from '../components/Headers';
import Footer from '../components/Footer';
import { endPointURL, get_data } from '../../api';
import CircularProgress from '@mui/material/CircularProgress';

const Gallery = () => {

    const [hovered, setHovered] = useState(null);
    const theme = useTheme();
    const matches_md = useMediaQuery(theme.breakpoints.down('md'))
    const [loader, setLoader] = useState(true)
    const [data, setData] = useState([])

    const gridStyle = {
        display: 'grid',
        gridTemplateColumns: matches_md ? 'repeat(1, 1fr)' : 'repeat(3, 1fr)',
        gap: '30px', // Adjust spacing between columns and rows
    };

    const columnStyle = (isHovered) => ({
        borderRadius: '8px', // Adjust border radius as needed
        overflow: 'hidden', // Ensures border-radius is applied correctly
        textAlign: 'center',
        cursor: 'pointer',
        padding: '16px', // Adjust padding as needed
        transform: isHovered ? 'scale(1.1)' : 'scale(1)', // Zoom effect on hover
        transition: 'transform 0.3s ease-in-out', // Smooth transition effect
    });

    const imageStyle = {
        height: 400,
        width: '100%',
        borderRadius: 10
    };

    const titleStyle = {
        marginTop: '8px', // Space between image and title
        fontSize: '18px',
        fontWeight: 500,
    };


    const getAllGallery = async () => {
        try {
            const data = await get_data(`gallery/get-all-gallery`);
            if (data.status) {
                setLoader(false);
                setData(data?.data);
            }
        } catch (error) {
            setLoader(false);
        }
    }
    useEffect(() => {
        getAllGallery()
    }, [])


    return (
        <>
            <Header />
            {
                loader ?
                    <>
                        <div style={{ display: 'flex', justifyContent: 'center', padding: '10% 0' }}>
                            <CircularProgress />
                        </div>
                    </>
                    :
                    <>
                        <div style={{ padding: matches_md ? '20% 7% 7%' : '10%' }} className='blog-page'>
                            <h2 style={{ fontWeight: 600, fontSize: 35, color: 'black', marginBottom: '3%' }}>Our Gallery</h2>
                            <div style={gridStyle}>
                                {data?.map((item, index) => (
                                    <div
                                        key={index}
                                        style={columnStyle(hovered === index)}
                                        onMouseEnter={() => setHovered(index)}
                                        onMouseLeave={() => setHovered(null)}
                                    >
                                        <img
                                            src={`${endPointURL}/uploads/gallery-picture/${item?.image}`}
                                            alt={`${item?.name} image`}
                                            style={imageStyle}
                                        />
                                        <h2 style={titleStyle}>{item?.name}</h2>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </>
            }
            <Footer />
        </>
    );
};

export default Gallery;
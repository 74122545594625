import Header from "../components/Headers"
import Footer from "../components/Footer"
import AllPosts from "../components/AllPosts"

export default function Blog() {

    return (
        <>
            <Header />
            <div style={{ paddingTop: '5%' }}>
                <AllPosts />
            </div>
            <Footer />
        </>
    )

}
import { FaMapMarkerAlt, FaPhone, FaEnvelopeOpen } from 'react-icons/fa';
import { FaFacebook, FaTwitter, FaGooglePlusG, FaLinkedinIn, FaInstagram } from 'react-icons/fa';

export default function Footer() {
    return (
        <section className="w3l-footer-28-main">
            <div className="footer-28">
                <div className="wrapper">
                    <div className="d-grid grid-col-4 footer-top-28">
                        <div className="footer-list-28">
                            <h6 className="footer-title-28">
                                Address Info<span className="line"></span>
                            </h6>
                            <ul>
                                <li>
                                    <p><FaMapMarkerAlt /> No. 92, 7th cross, Basavanapura main road, Bangalore 560036</p>
                                </li>
                                <li>
                                    <a href="tel:+7-800-999-800"><FaPhone /> +(91)-903-609-6000</a>
                                </li>
                                <li>
                                    <a href="mailto:contact@nivishka.com" className="mail"><FaEnvelopeOpen /> contact@nivishka.com</a>
                                </li>
                            </ul>
                        </div>
                        <div className="footer-list-28">
                            <h6 className="footer-title-28">
                                Quick Links<span className="line"></span>
                            </h6>
                            <ul>
                                <li><a href="/about">About Company</a></li>
                                <li><a href="/all-services">Our Services</a></li>
                                <li><a href="/quote">Request a Quote</a></li>
                                <li><a href="/partner-program">Join as Partner</a></li>
                            </ul>
                        </div>
                        <div className="footer-list-28">
                            <h6 className="footer-title-28">
                                List of Services<span className="line"></span>
                            </h6>
                            <ul className="d-grid footer-column-2">
                                <li><a href="/all-services">House Keeping</a></li>
                                <li><a href="/all-services">Security</a></li>
                                <li><a href="/all-services">Pantry Assistant</a></li>
                                <li><a href="/all-services">Lift Operator</a></li>
                                <li><a href="/all-services">Valet Parking</a></li>
                                <li><a href="/all-services">Non Security Staff</a></li>
                                <li><a href="/all-services">Facade Cleaning</a></li>
                                <li><a href="/all-services">Soft Services</a></li>
                                <li><a href="/all-services">Retail Staffing</a></li>
                                <li><a href="/all-services">Warehouse Staffing</a></li>
                                <li><a href="/all-services">Services on Demand</a></li>
                            </ul>
                        </div>
                        <div className="footer-list-28">
                            <h6 className="footer-title-28">
                                Support<span className="line"></span>
                            </h6>
                            <ul>
                                <li><a target='_blank' href="#">Documentation</a></li>
                                <li><a target='_blank' href="https://nivishka.com/privacy-policy">Privacy policy</a></li>
                                <li><a target='_blank' href="https://nivishka.com/terms-and-conditions">Terms of service</a></li>
                                <li><a target='_blank' href="/contact">24/7 support</a></li>
                            </ul>
                        </div>
                    </div>
                    <div className="midd-footer-28 align-center">
                        <div className="main-social-footer-28">
                            <a href="https://www.facebook.com/nivishka.in"><FaFacebook color='#3b5998' /></a>
                            <a href="#twitter"><FaTwitter color='#5599c4' /></a>
                            {/* <a href="#google"><FaGooglePlusG color='#d1321f' /></a> */}
                            <a href="#linkedin"><FaLinkedinIn color='#0877b5' /></a>
                            <a href="#instagram"><FaInstagram color='#c13584' /></a>
                        </div>
                        <p className="copy-footer-28">© 2024 Nivishka Services. All rights reserved</p>
                    </div>
                </div>
            </div>
        </section>
    );
}

import { useState } from "react";
import { post_data } from "../../api"
import Swal from 'sweetalert2';

export default function CTA() {

    const [email, setEmail] = useState('');
    const [disabled, setDisabled] = useState(false);

    const Toast = Swal.mixin({
        toast: true,
        position: "top",
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
    });


    const handleSubscribe = async () => {
        if (email.length === 0) {
            Toast.fire({
                icon: "error",
                text: "Email is required!",
                color: "red",
            })
        }
        else {
            setDisabled(true);

            const data = await post_data("newsletter/create-newsletter", { email })

            if (data?.status) {
                setEmail('');
                Toast.fire({
                    icon: "success",
                    text: "Thanks for subscribing us!",
                    color: "Green"
                })
            } else {
                Toast.fire({
                    icon: "error",
                    text: "Something Went Wrong",
                    color: "red"
                })
            }
            setDisabled(false);
        }
    }

    return (

        <section className="w3l-forms-28" style={{ backgroundColor: '#f8f9fa' }}>
            <div className="form-28">
                <div className="wrapper">
                    <div className="form-main-cont-28">
                        <div className="content-28-form text-center">
                            <span className="fa fa-pencil"></span>
                            <span className="fa fa-envelope-open-o"></span>
                            <span className="fa fa-paper-plane-o"></span>
                            <h4 className="title-cont-foem28">
                                Subscribe to stay informed on Services, Offers and Discounts.
                            </h4>
                            <form id="subscribe" className="d-grid forms-28-form">
                                <input
                                    value={email}
                                    type="email"
                                    onChange={(e) => {
                                        setEmail(e.target.value);
                                    }}
                                    id="emailSubscribe"
                                    placeholder="Enter email address"
                                />
                                <button
                                    disabled={disabled}
                                    onClick={handleSubscribe}
                                    id="subscribe_submit"
                                    type="button"
                                    className="actionbg"
                                >
                                    {disabled ? 'Subscribe...' : 'Subscribe'}
                                </button>
                            </form>
                            <p className="small-para-28form">
                                We will never share your email with 3rd parties and anyone else
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

import { useEffect, useState } from 'react';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { endPointURL, get_data } from "../../api"
import { CircularProgress } from '@mui/material';

const AllServicesComponent = () => {

    const [hovered, setHovered] = useState(null);
    const theme = useTheme();
    const matches_md = useMediaQuery(theme.breakpoints.down('md'))
    const [serviceData, setServicesData] = useState([])
    const [loader, setLoader] = useState(true)

    const getAllServices = async () => {
        try {
            const data = await get_data("services/get-all-services");
            if (data.status) {
                setLoader(false);
                setServicesData(data?.data?.services);
            }
        } catch (error) {
            setLoader(false);
            // console.log("Error while fetching Services", error);
        }
    }

    useEffect(() => {
        getAllServices();
    }, [])

    const gridStyle = {
        display: 'grid',
        gridTemplateColumns: matches_md ? 'repeat(2, 1fr)' : 'repeat(4, 1fr)',
        gap: '30px', // Adjust spacing between columns and rows
    };

    const columnStyle = (isHovered) => ({
        borderRadius: '8px', // Adjust border radius as needed
        boxShadow: '4px 4px 44px rgba(0,5,46,.08)', // Adjust box shadow as needed
        overflow: 'hidden', // Ensures border-radius is applied correctly
        textAlign: 'center',
        padding: '16px', // Adjust padding as needed
        backgroundColor: '#fff', // Background color for contrast
        transform: isHovered ? 'scale(1.1)' : 'scale(1)', // Zoom effect on hover
        transition: 'transform 0.3s ease-in-out', // Smooth transition effect
    });

    const imageStyle = {
        height: 150,
        width: 150,
        borderRadius: '50%',
    };

    const titleStyle = {
        marginTop: '8px', // Space between image and title
        fontSize: '18px',
        fontWeight: 500,
    };

    return (
        <>
            <div style={{ padding: '6% 10%' }}>
                <center>
                    <h5 className="heading">What we offer</h5>
                    <h3 className="heading">Our services</h3>
                </center>
                {
                    loader ?
                        <>
                            <div style={{ display: 'flex', justifyContent: 'center', width: '100%', marginTop: '10%' }}>
                                <CircularProgress />
                            </div>
                        </>
                        :
                        <>
                            <div style={gridStyle}>
                                {serviceData?.map((service, index) => (
                                    <div
                                        key={index}
                                        style={columnStyle(hovered === index)}
                                        onMouseEnter={() => setHovered(index)}
                                        onMouseLeave={() => setHovered(null)}
                                    >
                                        <img
                                            src={`${endPointURL}/uploads/service-picture/${service?.service_image}`}
                                            style={imageStyle}
                                        />
                                        <h2 style={titleStyle}>{service?.service_name}</h2>
                                    </div>
                                ))}
                            </div>
                        </>
                }
            </div>
        </>
    );
};

export default AllServicesComponent;
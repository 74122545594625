import axiosRequest from "../src/axios-request"

const endPointURL = "https://api.nivishka.com"
// const endPointURL = "http://localhost:8000"

const post_data = async (url, body) => {
    try {
        var response = await axiosRequest.post(`/api/${url}`, body);
        var data = response.data;
        return data;
    } catch (e) {
        return e;
    }
};

const get_data = async (url) => {
    try {
        var response = await axiosRequest.get(`/api/${url}`);
        var data = response.data;
        return data;
    } catch (e) {
        return e;
    }
};

export {
    endPointURL,
    post_data,
    get_data,
};
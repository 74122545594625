import ServicesSlider from "./ServicesSlider";

const Servies = () => {
    return (<>
        <section class="w3l-grids-4">
            <div id="grids4-block">
                <div class="wrapper">
                    <h5 class="heading">Services</h5>
                    <h3 class="heading">What we offer</h3>

                    <ServicesSlider />

                    {/* <div class="owl-carousel owl-theme text-center">
                        <div class="item">
                            <div class="grids4-info">
                                <a href="#URL"> <img src="assets/images/housekeeping.jpg" class="img-responsive" alt="" /></a>
                                <div class="info">
                                    <h5><a href="#URL">House keeping</a></h5>
                                    <p>To Keep your house clean and ensure everything is running well.</p>
                                </div>
                            </div>
                        </div>

                        <div class="item">
                            <div class="grids4-info">
                                <a href="#URL"> <img src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQt7sh_ntWLuSizUxAO7m58j92qFjhDQPgfZg&usqp=CAU" class="img-responsive" alt="" /></a>
                                <div class="info">
                                    <h5><a href="#URL">Security </a></h5>
                                    <p>To Keep your place secure with our well trained intelligent and smart gaurds.</p>
                                </div>
                            </div>
                        </div>
                        <div class="item">
                            <div class="grids4-info">
                                <a href="#URL"><img src="assets/images/cleaning.jpg" class="img-responsive" alt="" /></a>
                                <div class="info">
                                    <h5><a href="#URL">Cleaning</a></h5>
                                    <p>To Keep your spaces clean and ensure everybody stays hygeinic and healthy</p>
                                </div>
                            </div>
                        </div>
                        <div class="item">
                            <div class="grids4-info">
                                <a href="#URL"><img src="assets/images/plumbing.jpg" class="img-responsive" alt="" /></a>
                                <div class="info">
                                    <h5><a href="#URL">Plumbing</a></h5>
                                    <p>To take care of your plumbing needs and repairs</p>
                                </div>
                            </div>
                        </div>
                        <div class="item">
                            <div class="grids4-info">
                                <a href="#URL"><img src="assets/images/electrician.jpg" class="img-responsive" alt="" /></a>
                                <div class="info">
                                    <h5><a href="#URL">Electrician</a></h5>
                                    <p>We make all kinds of electricians services from repairs to wiring.</p>
                                </div>
                            </div>
                        </div>
                        <div class="item">
                            <div class="grids4-info">
                                <a href="#URL"><img src="assets/images/carpenter.jpg" class="img-responsive" alt="" /></a>
                                <div class="info">
                                    <h5><a href="#URL">Carpenter</a></h5>
                                    <p>To Take care of your furnitures and build custom designs for you.</p>
                                </div>
                            </div>
                        </div>


                        <div class="item">
                            <div class="grids4-info">
                                <a href="#URL"><img src="assets/images/wallpainting.jpg" class="img-responsive" alt="" /></a>
                                <div class="info">
                                    <h5><a href="#URL">Painting</a></h5>
                                    <p>To make your space look beautiful with our artistic painters.</p>
                                </div>
                            </div>
                        </div>
                    </div> */}

                </div>
            </div>
        </section>
    </>)
}

export default Servies;
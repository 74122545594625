export default function ServiceDetails() {

    const banner = {
        background: 'url(/images/tap.jpg)',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center'
    }

    return (
        <>
            <section className="w3l-inner-banner" style={banner}>
                <div className="wrapper">
                </div>
            </section>
            <section className="w3l-content-8">
                <section className="content-main">
                    <h5 className="heading">Service details</h5>
                    <h3 className="heading">House Keeping</h3>
                    <div className="wrapper">
                        <div className="grid">
                            <div className="left-align">
                                <h3>House Keeping Services for making it shine</h3>
                                <p className="main-pre">
                                    Housekeeping services come in for rescue to those who need help in maintaining their homes or commercial spaces.
                                    The housekeeping services ensure you have a neat and clean home/Other Place with no exposure to infections.
                                    Whether you are a working woman staying with a family or a bachelor staying away from home, housekeeping
                                    services always come in handy. The services provided are dusting, cleaning, sanitizing doors, windows, furniture,
                                    air vents, electronic appliances, deep clean the carpets, empty trash, etc.
                                </p>
                            </div>
                            <div className="right-align">
                                <p className="main-pre">
                                    The leading housekeeping services through Niviksha, which provides a comprehensive list of renowned, verified, and trustworthy firms and professionals. You can choose the best housekeeping services based on your location, popularity, ratings, and reviews on Niviksha.
                                </p>
                                <div className="iner-align">
                                    <div className="iner-left">
                                        <h4>What we do</h4>
                                        <a href="#">Electrical wiring work</a>
                                        <a href="#">Plumbing and Carpentry</a>
                                        <a href="#">Cleaning and house keeping</a>
                                        <a href="#">Furniture moving</a>
                                    </div>
                                    <div className="iner-right">
                                        <h4>How we work</h4>
                                        <a href="#">230+ professional experts</a>
                                        <a href="#">High quality materials</a>
                                        <a href="#">Project on time service</a>
                                    </div>
                                </div>
                            </div>
                            <div className="img">
                                <img src="/images/wallpainting.jpg" className="img-responsive" alt="Wall Painting" />
                            </div>
                            <div className="img">
                                <img src="/images/sinkcleaning.jpg" className="img-responsive" alt="Sink Cleaning" />
                            </div>
                        </div>
                    </div>
                </section>
            </section>
        </>
    );
}
import emailjs from 'emailjs-com';
import React, { useState } from 'react';
import { post_data } from '../../api';

export default function AllTypeServices() {
	const [loading, setLoading] = useState(false);
	const [success, setSuccess] = useState(false);
	const [error, setError] = useState('');
	const [formValues, setFormValues] = useState({});

	// const handleSubmit = (event) => {
	// 	event.preventDefault();
	// 	setLoading(true);

	// 	emailjs.send(
	// 		'service_66ewgws',
	// 		'template_8acn1nx',
	// 		formValues,
	// 		'MGuMOE4ZZvvdQxrlG'
	// 	).then((result) => {
	// 		setSuccess(true);
	// 		setLoading(false);
	// 	}, (error) => {
	// 		setLoading(false);
	// 	});
	// };


	const handleSubmit = async (event) => {
		event.preventDefault();
		try {
			const data = await post_data(`forms/send-service-request-mail`, formValues)
			if (data.status) {
				setSuccess(true);
				setLoading(false);
			}
			else {
				setLoading(false);
			}
		} catch (error) {
		}
	}


	const handleChange = (event) => {
		const { name, value } = event.target;
		setFormValues({ ...formValues, [name]: value });
	};


	return (
		<>
			<section className="w3l-form-16">
				<div className="form-16-mian">
					<div className="wrapper">
						<div className="forms-16-top">
							<div className="forms-16-info">
								<h5>Book service</h5>
								<h3>All types of Service Maintenance at your house</h3>
								<p>Having 16 years of operational experience, The top management reachable presence in south India excellent manpower resources are 100% statutory compliance and self-designed HR policies. In-house training facility is a strong administrative backup of employees motivational activities. The regular on-site training and evaluations of employee performance strong administrative backup Clear escalation matrix</p>
								<a href="/contact" className="read1">Schedule a visit</a>
								<a href="/all-services" className="read2">Services we offer</a>
							</div>
							<div className="form-right-inf">
								<div className="form-inner-cont">
									<h6>Book a service now<span className="line"></span></h6>
									<form id="book-a-service-form" onSubmit={handleSubmit}>
										<div className="d-grid book-form">
											<div className="form-input">
												<input type="text" name="name" onChange={handleChange} placeholder="Your Name" required />
											</div>
											<div className="form-input">
												<input type="email" name="email" onChange={handleChange} placeholder="Email Address" required />
											</div>
											<div className="form-input">
												<input type="number" name="phone" onChange={handleChange} placeholder="Contact Number" required />
											</div>
											<div className="form-input">
												<select name="services" onChange={handleChange} >
													<option value="Select service">Select service</option>
													<option value="Carpentry">Carpentry</option>
													<option value="Cleaning">Cleaning</option>
													<option value="Electrical wiring">Electrical wiring</option>
													<option value="Plumbing">Plumbing</option>
												</select>
											</div>
										</div>
										<button className="btn" type="submit" id="submit-btn">Book Service</button>
										{loading && (
											<p style={{ color: 'white', fontSize: '20px', marginTop: '20px' }} id="loading">
												Sending Details
											</p>
										)}
										{success && !loading && (
											<p style={{ color: 'white', fontSize: '16px' }} id="success-msg">
												You will receive a call very soon.
											</p>
										)}
										{error && !loading && !success && (
											<p style={{ color: 'white', fontSize: '16px' }} id="error-msg">
												{error}
											</p>
										)}
										<p>We will never share your details with 3rd parties.</p>
									</form>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
		</>
	);
}

import React from 'react';
import Slider from 'react-slick';
import { Card, CardContent, CardMedia, Typography, Grid, Container } from '@mui/material';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const services = [
    {
        title: 'House keeping',
        description: 'To keep your house clean and ensure everything is running well.',
        image: '/images/table-cleaning.jpg', // Replace with actual image path
    },
    {
        title: 'Security',
        description: 'To keep your place secure with our well-trained intelligent and smart guards.',
        image: '/images/security.jpg', // Replace with actual image path
    },
    {
        title: 'Cleaning',
        description: 'To keep your spaces clean and ensure everybody stays hygienic and healthy.',
        image: '/images/cleaning.jpg', // Replace with actual image path
    },

    {
        title: 'Plumbing',
        description: 'To take care of your plumbing needs and repairs.',
        image: '/images/plumbing.jpg', // Replace with actual image path
    },
    {
        title: 'Electrician',
        description: 'We make all kinds of electricians services from repairs to wiring.',
        image: '/images/electrician.jpg', // Replace with actual image path
    },
    {
        title: 'Carpenter',
        description: 'To take care of your furniture and build custom designs for you.',
        image: '/images/carpenter.jpg', // Replace with actual image path
    },
    {
        title: 'Painting',
        description: 'To make your space beautiful with our artistics painters.',
        image: '/images/wallpainting.jpg', // Replace with actual image path
    },
];

export default function ServicesSlider() {
    const settings = {
        dots: true, // Show navigation dots
        infinite: true, // Loop through slides infinitely
        speed: 500, // Transition speed
        slidesToShow: 3, // Number of slides to show at once
        slidesToScroll: 1, // Number of slides to scroll at once
        responsive: [
            {
                breakpoint: 1024, // At 1024px or below
                settings: {
                    slidesToShow: 2, // Show 2 slides at a time
                    slidesToScroll: 1,
                    infinite: true,
                    dots: true
                }
            },
            {
                breakpoint: 600, // At 600px or below
                settings: {
                    slidesToShow: 1, // Show 1 slide at a time
                    slidesToScroll: 1
                }
            }
        ]
    };


    const showSlider = (services) => {
        return services?.map((service, index) => {
            return (<div key={index}>
                <div style={{ padding: '5px 10px' }}>
                    <Card sx={{ height: '100%', display: 'flex', flexDirection: 'column', border: '1px solid gainsboro', boxShadow: 'none' }}>
                        <CardMedia
                            component="img"
                            height="200"
                            image={service.image}
                            alt={service.title}
                        />
                        <CardContent>
                            <Typography variant="h6" gutterBottom style={{ textAlign: 'center', color: 'black', fontWeight: 500 }}>
                                {service.title}
                            </Typography>
                            <Typography variant="body2" color="textSecondary" style={{ textAlign: 'center' }}>
                                {service.description}
                            </Typography>
                        </CardContent>
                    </Card>
                </div>
            </div>)
        })
    }

    return (
        <Container sx={{ mt: 5 }}>
            <Slider {...settings}>
                {showSlider(services)}
            </Slider>
        </Container>
    );
}

export default function WhyChooseUs(){
    return(<>
    <section class="w3l-features-17">
			<div class="features-17_sur">
				<div class="wrapper">
					<h5 class="heading">Some features</h5>
					<h3 class="heading">Why choose us</h3>
				    <div class="features-17-top_sur">
						<div class="features-17-left_sur">
							<h3>We take great pride in all the services we offer, but
								what makes our company special?</h3>
							<p>We make sure only workers who prove their expertise serve you. We train our workers with worldclass training.</p>
							<p>In house training facility is a strong administrative backup of employees motivational activities.The regular on site trainings and evaluations of employee performance strong administrative backup Clear escalation matrix</p>
						</div>
						<div class="features-17-right_sur">
							<div class="features-17-right_sur_inner">
								<a href="#link"><span class="fa fa-check"></span> Well trained technician.</a>
								<a href="#link"><span class="fa fa-check"></span> A hassle free Service</a>
								<a href="#link"><span class="fa fa-check"></span> Using Best Quality tools.</a>
								<a href="#link"><span class="fa fa-check"></span> Money is on safe Hand</a>
								<a href="#link"><span class="fa fa-check"></span> 100% satisfactory work.</a>
								<a href="#link"><span class="fa fa-check"></span> Work completion in Time.</a>
								<a href="#link"><span class="fa fa-check"></span> Reasonable Cost</a>
								<a href="#link"><span class="fa fa-check"></span> Expert consultant Team</a>
								<a href="#link"><span class="fa fa-check"></span> On time response</a>
								<a href="#link"><span class="fa fa-check"></span> Get Expert Technician</a>
								<a href="#link"><span class="fa fa-check"></span> Well trained technician.</a>
								<a href="#link"><span class="fa fa-check"></span> A hassle free Service</a>
							</div>
						</div>
					</div>
				</div>
			</div>
	</section>
    </>)
}
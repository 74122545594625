import Header from "../components/Headers";
import RequestQuote from "../components/RequestQuote";
import Footer from "../components/Footer";

export default function Quote() {
    return (<>
        <Header />
        <RequestQuote />
        <Footer />
    </>)
}
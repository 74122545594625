import Header from "../components/Headers";
import HeroSection from "../components/HeroSection";
import Features from "../components/Features";
import Servies from "../components/Services";
import AllTypeServices from "../components/AllTypeServices";
import Clients from "../components/Clients";
import CTA from "../components/CTA";
import Footer from "../components/Footer";
import Brochure from "../components/Brochure";

export default function Home() {
    return (
        <>
            <Header />
            <HeroSection />
            <Features />
            <Servies />
            <AllTypeServices />
            <Brochure />
            <Clients />
            <CTA />
            <Footer />
        </>
    )
}
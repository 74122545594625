import emailjs from 'emailjs-com';
import React, { useState } from 'react';
import { post_data } from '../../api';

export default function RequestQuote() {

    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState(false);
    const [error, setError] = useState('');
    const [formValues, setFormValues] = useState({});

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormValues({ ...formValues, [name]: value });
    };

    // const handleSubmit = (e) => {
    //     e.preventDefault();
    //     setLoading(true);
    //     setSuccess(false);
    //     setError('');

    //     // Simulating form submission
    //     emailjs.send(
    // 		'service_66ewgws',
    // 		'template_chcg0bq',
    // 		formValues,
    // 		'MGuMOE4ZZvvdQxrlG'
    // 	).then((result) => {
    // 		setSuccess(true);
    // 		setLoading(false);
    // 	}, (error) => {
    // 		console.log("error.text",error);
    // 		setLoading(false);
    // 	});
    // };

    const handleSubmit = async (event) => {
        event.preventDefault();
        try {
            const data = await post_data(`forms/send-request-quote-mail`, formValues)
            if (data.status) {
                setSuccess(true);
                setLoading(false);
            }
            else {
                setLoading(false);
            }
        } catch (error) {
        }
    }

    const banner = {
        background: 'url(/images/tap.jpg)',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center'
    };

    return (
        <>
            <section className="w3l-inner-banner" style={banner}>
                <div className="wrapper"></div>
            </section>

            <section className="w3l-contacts-12">
                <div className="wrapper">
                    <h5 className="heading">Expect Quality</h5>
                    <h3 className="heading">Request a Quote</h3>
                    <div className="contacts12-main">
                        <form id="request-form" onSubmit={handleSubmit}>
                            <div className="main-input">
                                <input
                                    type="text"
                                    name="name"
                                    onChange={handleChange}
                                    placeholder="Contact Person full name"
                                    className="contact-input1"
                                    required
                                />
                                <input
                                    type="text"
                                    name="companyName"
                                    onChange={handleChange}
                                    placeholder="Name of the Company"
                                    className="contact-input1"
                                    required
                                />
                                <input
                                    type="email"
                                    name="email"
                                    placeholder="Contact Person email address"
                                    onChange={handleChange}
                                    className="contact-input1"
                                    required
                                />
                                <input
                                    type="number"
                                    name="phone"
                                    placeholder="Contact Person phone number"
                                    onChange={handleChange}
                                    className="contact-input1"
                                    required
                                />
                                <select
                                    name="services"
                                    placeholder="Services"
                                    onChange={handleChange}
                                    className="contact-input1"
                                    required
                                >
                                    <option>House Keeping</option>
                                    <option>Security</option>
                                    <option>Pantry Assistant</option>
                                    <option>Lift Operator</option>
                                    <option>Valet Parking</option>
                                    <option>Non Security Staff</option>
                                    <option>Facade Cleaning</option>
                                    <option>Soft Services</option>
                                    <option>Fork Lift Operator</option>
                                    <option>Retail Staffing</option>
                                    <option>Warehouse Staffing</option>
                                    <option>Services on Demand</option>
                                </select>
                                <input
                                    type="datetime-local"
                                    name="datetime"
                                    placeholder="Date Time"
                                    onChange={handleChange}
                                    className="contact-input1"
                                    required
                                />
                            </div>
                            <textarea
                                className="contact-textarea"
                                name="message"
                                onChange={handleChange}
                                placeholder="Your message here"
                                required
                            ></textarea>
                            <button
                                className="actionbg btn"
                                type="submit"
                                id="submit-btn"
                                disabled={loading}
                            >
                                Submit
                            </button>
                        </form>

                        {loading && (
                            <p
                                style={{
                                    color: 'blue',
                                    fontSize: '20px',
                                    marginTop: '20px',
                                }}
                                id="loading"
                            >
                                Sending Details
                            </p>
                        )}

                        {success && (
                            <p
                                style={{
                                    color: 'green',
                                    fontSize: '16px',
                                    marginTop: '10px',
                                }}
                                id="success-msg"
                            >
                                You will receive a call very soon.
                            </p>
                        )}

                        {error && (
                            <p
                                style={{
                                    color: 'red',
                                    fontSize: '16px',
                                    marginTop: '10px',
                                }}
                                id="error-msg"
                            >
                                {error}
                            </p>
                        )}
                    </div>
                </div>
            </section>
        </>
    );
}

import './App.css';
import React, { useState } from "react";
import { useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from "../src/website/pages/Home"
import About from './website/pages/About';
import Quote from './website/pages/Quote';
import Careers from './website/pages/Careers';
import Contact from './website/pages/Contact';
import HouseKeeping from './website/pages/HouseKeeping';
import Security from './website/pages/Security';
import Demolition from './website/pages/Demolition';
import AllServices from './website/pages/AllServices';
import Partner from './website/pages/Partner';
import Blog from './website/pages/Blog';
import SinglePost from './website/pages/SinglePost';
import CategoryPage from './website/pages/CategoryPage';
import Gallery from './website/pages/Gallery';

function App() {

  return (
    <div className="App">
      <Router>
        <Routes>
          <Route element={<Home />} path="/" />
          <Route element={<About />} path="/about" />
          <Route element={<Quote />} path="/quote" />
          <Route element={<Careers />} path="/careers" />
          <Route element={<Contact />} path="/contact" />
          <Route element={<HouseKeeping />} path="/house-keeping" />
          <Route element={<Security />} path="/security" />
          <Route element={<Demolition />} path="/demolition" />
          <Route element={<AllServices />} path="/all-services" />
          <Route element={<Partner />} path="/partner-program" />
          <Route element={<Gallery />} path="/gallery" />
          <Route element={<Blog />} path="/blog" />
          <Route element={<SinglePost />} path="/post/:slug" />
          <Route element={<CategoryPage />} path="/category/:categoryName" />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
export default function Location() {
    return (
        <>
            <section className="w3l-contacts-4">
                <div id="contacts4-block">
                    <div className="contact">
                        <iframe
                            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d971.8538730083446!2d77.70521649069835!3d13.00919228519786!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bae11ea950d22fd%3A0xdcc46078c9f05704!2sNivishka%20Services!5e0!3m2!1sen!2sin!4v1632817946870!5m2!1sen!2sin"
                            style={{ border: 0 }}
                            allowFullScreen=""
                            loading="lazy"
                        ></iframe>

                        <div className="cont-details">
                            <h5>Location</h5>
                            <p>
                                Please contact us for any queries. We promise to reply as early as possible.
                            </p>
                            <address>
                                <p>
                                    <span className="fa fa-map-marker"></span>No. 92, 7th cross
                                </p>
                                <p>Basavanapura main road</p>
                                <p>Bangalore 560036</p>
                            </address>
                            <p>
                                <span className="fa fa-envelope-open-o"></span> Email: <a href="mailto:contact@nivishka.com">contact@nivishka.com</a>
                            </p>
                            <p>
                                <span className="fa fa-phone"></span> Tel: <a href="tel:+(91)-903-609-6000">+(91)-903-609-6000</a>
                            </p>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

import Footer from "../components/Footer";
import Header from "../components/Headers";
import GetInTouch from "../components/GetInTouch";
import Location from "../components/Location";


export default function Contact() {
    return (<>
        <Header />
        <GetInTouch />
        <Location />
        <Footer />
    </>)
}
import Footer from "../components/Footer";
import Header from "../components/Headers";
import WorkWith from "../components/WorkWith";

export default function Careers() {
    return (<>
        <Header />
        <WorkWith />
        <Footer />
    </>)
}
import React, { useEffect, useState } from 'react';
import { get_data } from '../../api';

export default function Header() {
    const [data, setData] = useState([]);

    const getAllCategory = async () => {
        try {
            const response = await get_data(`category/get-all-category-v2`);
            if (response.status) {
                setData(response.data);
            }
        } catch (error) {
            console.error("Error fetching categories", error);
        }
    };

    useEffect(() => {
        getAllCategory();
    }, []);

    const convertedString = (originalString) => {
        return `/category/${originalString?.toLowerCase().replace(/\s+/g, '-')}`;
    };

    return (
        <div className="w3l-headers-9">
            <header style={{ backgroundColor: 'white' }}>
                <div className="wrapper" style={{ width: '98%' }}>
                    <div className="header" style={{ color: 'green' }}>
                        <div>
                            <h1>
                                <a className="logo" href="/">
                                    <img
                                        src="https://nivishka.com/images/nivishka-logo.png"
                                        alt="Your logo"
                                        title="Your logo"
                                        style={{ height: '45px' }}
                                    />
                                </a>
                            </h1>
                        </div>
                        <div className="bottom-menu-content">
                            <input type="checkbox" id="nav" />
                            <label htmlFor="nav" className="menu-bar"></label>
                            <nav>
                                <ul className="menu">
                                    <li><a href="/" className="link-nav" style={{ color: 'black' }}>Home</a></li>
                                    <li><a href="/about" className="link-nav" style={{ color: 'black' }}>About</a></li>
                                    <li><a href="/gallery" className="link-nav" style={{ color: 'black' }}>Gallery</a></li>

                                    <li>
                                        <label htmlFor="drop-blog" className="toggle toggle-2">Blog <span className="fa fa-caret-down" aria-hidden="true"></span></label>
                                        <a href="/blog" className="link-nav dropdown-toggle" style={{ color: 'black' }}>Blog <span className="fa fa-caret-down" aria-hidden="true"></span></a>
                                        <input type="checkbox" id="drop-blog" />
                                        <ul>
                                            {data?.slice(0, 7)?.map((item) => (
                                                <li key={item.id}><a href={convertedString(item.category_name)}>{item.category_name}</a></li>
                                            ))}
                                        </ul>
                                    </li>

                                    <li>
                                        <label htmlFor="drop-services" className="toggle toggle-2">Services <span className="fa fa-caret-down" aria-hidden="true"></span></label>
                                        <a href="#shop" className="link-nav dropdown-toggle" style={{ color: 'black' }}>Services <span className="fa fa-caret-down" aria-hidden="true"></span></a>
                                        <input type="checkbox" id="drop-services" />
                                        <ul>
                                            <li><a href="/house-keeping">House Keeping</a></li>
                                            <li><a href="/security">Security</a></li>
                                            <li><a href="/demolition">Demolition</a></li>
                                            <li><a href="/all-services">All Services</a></li>
                                        </ul>
                                    </li>

                                    <li><a href="/quote" className="link-nav" style={{ color: 'black' }}>Request A Quote</a></li>
                                    <li><a href="/careers" className="link-nav" style={{ color: 'black' }}>Careers</a></li>
                                    <li><a href="/contact" className="link-nav" style={{ color: 'black' }}>Contact</a></li>
                                </ul>
                            </nav>
                        </div>
                    </div>
                </div>
            </header>
        </div>
    );
}

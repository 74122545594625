import Footer from "../components/Footer";
import Header from "../components/Headers";
import ServiceDetailsSecurtiy from "../components/ServiceDetailsSecurity";

export default function Security(){
    return(<>
    <Header />
    <ServiceDetailsSecurtiy />
    <Footer />
    </>)
}
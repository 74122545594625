import { Grid, Pagination, Typography, useMediaQuery } from "@mui/material";
import { get_data } from "../../api";
import { useEffect, useState } from "react";
import CircularProgress from '@mui/material/CircularProgress';
import PostCard from "../components/PostCard"
import Header from "../components/Headers";
import Footer from "../components/Footer";
import { useParams } from "react-router-dom";
import { useTheme } from '@mui/material/styles';

const CategoryPage = () => {

    const theme = useTheme();
    const matches_md = useMediaQuery(theme.breakpoints.down('md'))

    const styles = {
        container: {
            display: 'flex',
            flexDirection: 'column',
            padding: '6%',
        },
        card: {
            flex: '1 1 calc(33.33% - 20px)',
            boxSizing: 'border-box',
        },
        page_header: {
            background: '#F5FFF5',
            padding: matches_md ? '25% 10% 6%' : '9% 10% 6%'
        }
    };

    const [loader, setLoader] = useState(true);
    const [postData, setPostData] = useState([]);

    const [totalPages, setTotalPages] = useState(null);
    const [totalPost, setTotalPost] = useState(null);
    const [currentPage, setCurrentPage] = useState(1);

    const { categoryName } = useParams();

    function convertToTitleCase(categoryName) {
        return categoryName
            .split('-')
            .map(word => word?.charAt(0).toUpperCase() + word?.slice(1))
            .join(' ');
    }

    const getCategoryName = convertToTitleCase(categoryName)


    const getAllPosts = async () => {
        try {
            const data = await get_data(`post/get-all-posts-by-category/${getCategoryName}?pageNumber=${currentPage}`);
            if (data.status) {
                setLoader(false);
                setPostData(data?.data?.posts);
                setTotalPages(data?.data?.totalPages);
                setTotalPost(data?.data?.totalPost);
            }
        } catch (error) {
            setLoader(false);
        }
    }
    useEffect(() => {
        getAllPosts()
    }, [currentPage])

    const itemsPerPage = 15;
    const count = postData?.length;


    const startEntry = (currentPage - 1) * itemsPerPage + 1;
    const endEntry = Math.min(currentPage * itemsPerPage, totalPost);

    const handlePageChange = (event, value) => {
        setCurrentPage(value);
        setLoader(true)
    };

    return (
        <>
            <Header />
            {
                loader ?
                    <>
                        <div style={{ display: 'flex', justifyContent: 'center', padding: '10% 0' }}>
                            <CircularProgress />
                        </div>
                    </>
                    :
                    <>
                        <div style={styles.page_header}>
                            <h2 style={{ fontWeight: 600, fontSize: 40 }}>{getCategoryName}</h2>
                        </div>
                        <div style={styles.container}>
                            <Grid container spacing={4}>
                                {
                                    postData?.map((item, i) => {
                                        return (
                                            <Grid item md={3} key={i}>
                                                <PostCard item={item} />
                                            </Grid>
                                        )
                                    })
                                }
                            </Grid>

                            {postData?.length > 0 ? (
                                <div
                                    style={{
                                        borderTop: "1px dashed gainsboro",
                                        paddingTop: "2%",
                                        display: "flex",
                                        marginTop: '5%',
                                        justifyContent: "space-between",
                                    }}
                                >
                                    <Typography
                                        style={{
                                            fontWeight: 500,
                                            fontSize: 15,
                                            color: "black",
                                        }}
                                    >{`Showing ${startEntry} to ${endEntry} of ${totalPost} Records`}</Typography>
                                    <Pagination
                                        count={totalPages}
                                        page={currentPage}
                                        onChange={handlePageChange}
                                        color="primary"
                                    />
                                </div>
                            ) : (
                                <>
                                    <h3 style={{ fontWeight: 600 }}>No Post available!</h3>
                                </>
                            )
                            }
                        </div>

                    </>
            }
            <Footer />
        </>
    );
}

export default CategoryPage;

import React, { useState } from 'react';
import { post_data } from '../../api';

export default function WorkWith() {
    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState(false);
    const [error, setError] = useState('');
    const [formValues, setFormValues] = useState({});

    const handleChange = (event) => {
        const { name, value } = event.target;
        setFormValues({ ...formValues, [name]: value });
    }

    const handleFileChange = (event) => {
        setFormValues({ ...formValues, file: event.target.files[0] });
    }

    const banner = {
        background: 'url(/images/tap.jpg)',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center'
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        setLoading(true);
        setError('');
        setSuccess(false);

        const formData = new FormData();
        formData.append('name', formValues.name);
        formData.append('email', formValues.email);
        formData.append('phone', formValues.phone);
        formData.append('message', formValues.message);
        formData.append('careers_file', formValues.file);

        try {
            const data = await post_data(`forms/send-careers`, formData)
            if (data.status) {
                setSuccess(true);
                setLoading(false);
            }
            else {
                setLoading(false);
            }
        } catch (error) {
        }
    }

    return (
        <>
            <section className="w3l-inner-banner" style={banner}>
                <div className="wrapper"></div>
            </section>

            <section className="w3l-contacts-12">
                <div className="wrapper">
                    <h5 className="heading">Expect Quality</h5>
                    <h3 className="heading">Work with Nivishka</h3>
                    <div className="contacts12-main">
                        <form id="career-form" onSubmit={handleSubmit}>
                            <div className="main-input">
                                <input
                                    type="text"
                                    name="name"
                                    onChange={handleChange}
                                    placeholder="Your full name"
                                    className="contact-input1"
                                    required
                                />
                                <input
                                    type="email"
                                    name="email"
                                    onChange={handleChange}
                                    placeholder="Your email address"
                                    className="contact-input1"
                                    required
                                />
                                <input
                                    type="number"
                                    name="phone"
                                    onChange={handleChange}
                                    placeholder="Your phone number"
                                    className="contact-input1"
                                    required
                                />
                                <input
                                    type="file"
                                    name="file"
                                    onChange={handleFileChange}
                                    placeholder="Resume"
                                    className="contact-input1"
                                    required
                                />
                            </div>
                            <textarea
                                className="contact-textarea"
                                onChange={handleChange}
                                name="message"
                                placeholder="Your message here"
                                required
                            ></textarea>
                            <button
                                className="actionbg btn"
                                type="submit"
                                id="submit-btn"
                            >
                                Submit
                            </button>
                            {loading && (
                                <p
                                    style={{
                                        color: 'blue',
                                        fontSize: '20px',
                                        marginTop: '20px',
                                    }}
                                    id="loading"
                                >
                                    Sending Details
                                </p>
                            )}
                            {success && !loading && (
                                <p
                                    style={{
                                        color: 'green',
                                        fontSize: '16px',
                                        marginTop: '10px',
                                    }}
                                    id="success-msg"
                                >
                                    You will receive a call very soon.
                                </p>
                            )}
                            {error && !loading && !success && (
                                <p
                                    style={{
                                        color: 'red',
                                        fontSize: '16px',
                                        marginTop: '10px',
                                    }}
                                    id="error-msg"
                                >
                                    {error}
                                </p>
                            )}
                        </form>
                    </div>
                </div>
            </section>
        </>
    );
}

import React from 'react';
import { FaRegSmile, FaUsers, FaHome, FaGavel } from 'react-icons/fa';

export default function OurStats() {
    return (
        <>
            <div className="w3l-specifications-5">
                {/* Specifications5 block */}
                <section id="specifications5-block">
                    <div className="wrapper">
                        <div className="d-grid align-specifications5-cols">
                            <div className="specifications5-left">
                                <h5 className="heading">Our Stats</h5>
                                <h3 className="heading">Some Company facts</h3>
                                <p>
                                    We at Nivishka services have worked hard for the last 16 years to bring the best services to the doorstep of our customers. We believe in being the best in the industry and also understand that being the best is to continually achieve excellence by providing quality services to our customers.
                                </p>
                            </div>
                            <div className="specifications5-right">
                                <div className="d-grid grid-col-2">
                                    <div className="specification">
                                        <FaRegSmile />
                                        <h4>30+</h4>
                                        <p>Services</p>
                                    </div>
                                    <div className="specification">
                                        <FaUsers />
                                        <h4>300+</h4>
                                        <p>Semi skilled staff</p>
                                    </div>
                                    <div className="specification spec-gap">
                                        <FaHome />
                                        <h4>100+</h4>
                                        <p>Projects completed</p>
                                    </div>
                                    <div className="specification spec-gap">
                                        <FaGavel />
                                        <h4>200+</h4>
                                        <p>Skilled Staff</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </>
    );
}

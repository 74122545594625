import Footer from "../components/Footer";
import Header from "../components/Headers";
import ServiceDetailsDemolition from "../components/ServiceDetailsDemolition";

export default function Demolition(){
    return(<>
    <Header />
    <ServiceDetailsDemolition />
    <Footer />
    </>)
}
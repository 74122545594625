export default function Features() {
    return (
        <>
            <section class="w3l-feature-9">
                <div class="main-w3">
                    <div class="wrapper">
                        <div class="d-flex main-cont-wthree-fea">
                            <div class="grids-feature">
                                <span class="fa fa-bath"></span>
                                <h4><a href="#feature" class="title-head">Best Quality</a></h4>
                                <p>We at Nivishka Services make sure our client's get exactly what they want and have a delightful experience.</p>
                            </div>
                            <div class="grids-feature">
                                <span class="fa fa-cogs"></span>
                                <h4><a href="#feature" class="title-head">Expert Advice</a></h4>
                                <p>We have a team of experts who advice our workers and clients to better deliver the service</p>
                            </div>
                            <div class="grids-feature">
                                <span class="fa fa-users"></span>
                                <h4><a href="#feature" class="title-head">Labour Expertise</a></h4>
                                <p>We train and quality test our workers. Only those who can prove their expertise get the oppurtunity to serve you.</p>
                            </div>
                            <div class="grids-feature">
                                <span class="fa fa-clock-o"></span>
                                <h4><a href="#feature" class="title-head">On Time Service</a></h4>
                                <p>We value your time, we promise to serve you exactly the time you the desire. </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}
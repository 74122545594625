import Header from "../components/Headers"
import AboutHero from "../components/AboutHero"
import WhyChooseUs from "../components/WhyChooseUs"
import ProfessionalServices from "../components/ProfessionalServices"
import OurStats from "../components/OurStats"
import Footer from "../components/Footer"
export default function About() {

    const banner = {
        background: 'url(/images/tap.jpg)',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
    }

    return (<>
        <Header />
        <section className="w3l-inner-banner" style={banner}>
            <div className="wrapper"></div>
        </section>
        <AboutHero />
        <WhyChooseUs />
        <ProfessionalServices />
        <OurStats />
        <Footer />
    </>)
}
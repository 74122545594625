import { FaHeadphones, FaMoneyBill } from 'react-icons/fa';

export default function AboutHero() {
    return (
        <section className="w3l-content-with-photo-4">
            <div id="content-with-photo4-block">
                <div className="wrapper">
                    <h5 className="heading">Our company</h5>
                    <h3 className="heading">Few words about us</h3>
                    <div className="cwp4-two">
                        <div className="cwp4-text">
                            <h4>16 years of experience in home service business</h4>
                            <p>
                                The NIVISHKA Services is one of Karnataka's well-known facility service providers. The group commenced its operations as a source of providing security and facility management services and its components in domestic and became the market leader for security services in South India.
                            </p>
                            <p>
                                NIVISHKA Services started its operations in 2008 under the leadership of Mr. Durjyodhan Mishra, CPO, with a background in Security and Facility Services. HQ is based in Bangalore. <br />
                                The burgeoning entity of 2008 has blossomed into a fine-tuned, technologically advanced, and timely delivery-capable organization.<br />
                            </p>
                            <p>
                                Driven by business processes that are dynamic, quality that is world-class, and an innovative approach to understanding market dynamics, the group moved its core competence into other diversified fields such as: “Surya Security Services“. As it rises to conquer new horizons, its commitment and steadfast drive to excel and achieve, fuels its aspirations. NIVISHKA Services strives to deliver beyond expectations and envisions a leadership position to be the best in the world.
                            </p>
                            <ul>
                                <li><FaHeadphones /> 24/7 online Services available</li>
                                <li><FaMoneyBill /> Affordable Price - No hidden cost</li>
                                <li><FaHeadphones /> Professional HandyMan</li>
                            </ul>
                        </div>
                        <div className="cwp4-image">
                            <img src="/images/About us Image.jpg" className="img-responsive" alt="About us" />
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

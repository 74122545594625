export default function ProfessionalServices() {

    const banner = {
        background: 'url(/images/cleaning.jpg)',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        backgroundAttachment: 'fixed'
    }

    return (
        <>
            <section className="w3l-cover-3" style={banner}>
                <div className="cover top-bottom">
                    <div className="wrapper">
                        <div className="middle-section text-center">
                            <div className="section-width">
                                <h2>We provide professional services with an amazing team</h2>
                                <p>We make sure you get the best service in the industry at your fingertips.</p>
                                <a href="/quote" className="btn">Get a quote</a>
                                <a href="/contact" className="contact">Contact us</a>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}
